import { getValidPublicRoomIds } from '@common/types/room-info';
import { atom, useAtomValue } from 'jotai';
import { deploymentVersion, surface } from '@/environment';
import { getCurrentRoomId } from '@/utils';

export const roomPortalAtom = atom<string | null>(null);

export const useIsInPublicRoom = () => {
  const roomPortal = useAtomValue(roomPortalAtom);
  if (surface === 'discord') {
    return roomPortal === null ? false : true;
  } else {
    const currentRoomId = getCurrentRoomId();
    const validPublicRoomIds = getValidPublicRoomIds(deploymentVersion);
    return currentRoomId && validPublicRoomIds.includes(currentRoomId);
  }
};
