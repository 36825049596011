import { CosmeticItem } from './cosmeticTypes';

export const PurchaseablePriceTier = {
  D: 25,
  C: 65,
  B: 330,
  A: 1170,
} as const;

export const allCosmeticItems: CosmeticItem[] = [
  {
    id: 'Top_DefaultGray.png',
    filename: 'Top_DefaultGray.png',
    type: 'Top',
    availability: 'default',
    displayName: 'Default',
    price: 0,
  },
  {
    id: 'Mid_DefaultGray.png',
    filename: 'Mid_DefaultGray.png',
    type: 'Mid',
    availability: 'default',
    displayName: 'Default',
    price: 0,
  },
  {
    id: 'Bottom_DefaultGray.png',
    filename: 'Bottom_DefaultGray.png',
    type: 'Bottom',
    availability: 'default',
    displayName: 'Default',
    price: 0,
  },
  {
    id: 'Bottom_DefaultBlack.png',
    filename: 'Bottom_DefaultBlack.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Black',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Bottom_DefaultBlue.png',
    filename: 'Bottom_DefaultBlue.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Blue',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Bottom_DefaultGreen.png',
    filename: 'Bottom_DefaultGreen.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Green',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Bottom_DefaultPink.png',
    filename: 'Bottom_DefaultPink.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Pink',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Bottom_DefaultPurple.png',
    filename: 'Bottom_DefaultPurple.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Purple',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Bottom_DefaultRed.png',
    filename: 'Bottom_DefaultRed.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Red',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Bottom_DefaultYellow.png',
    filename: 'Bottom_DefaultYellow.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Yellow',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Mid_DefaultBlack.png',
    filename: 'Mid_DefaultBlack.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Black',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Mid_DefaultBlue.png',
    filename: 'Mid_DefaultBlue.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Blue',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Mid_DefaultGreen.png',
    filename: 'Mid_DefaultGreen.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Green',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Mid_DefaultPink.png',
    filename: 'Mid_DefaultPink.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Pink',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Mid_DefaultPurple.png',
    filename: 'Mid_DefaultPurple.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Purple',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Mid_DefaultRed.png',
    filename: 'Mid_DefaultRed.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Red',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Mid_DefaultYellow.png',
    filename: 'Mid_DefaultYellow.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Yellow',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Top_DefaultBlack.png',
    filename: 'Top_DefaultBlack.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Black',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Top_DefaultBlue.png',
    filename: 'Top_DefaultBlue.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Blue',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Top_DefaultGreen.png',
    filename: 'Top_DefaultGreen.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Green',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Top_DefaultPink.png',
    filename: 'Top_DefaultPink.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Pink',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Top_DefaultPurple.png',
    filename: 'Top_DefaultPurple.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Purple',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Top_DefaultRed.png',
    filename: 'Top_DefaultRed.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Red',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Top_DefaultYellow.png',
    filename: 'Top_DefaultYellow.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Yellow',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Bottom_WizardRobe.png',
    filename: 'Bottom_WizardRobe.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Wizard',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_AviatorHat.png',
    filename: 'Top_AviatorHat.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Aviator',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_BallCap.png',
    filename: 'Top_BallCap.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Ball Cap',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_Beach.png',
    filename: 'Top_Beach.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Beach',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Top_Brain.png',
    filename: 'Top_Brain.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Brain',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Top_Cactus.png',
    filename: 'Top_Cactus.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Cactus',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Top_CatBeanie.png',
    filename: 'Top_CatBeanie.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Cat Beanie',
    price: PurchaseablePriceTier.C,
  },
  // Dogfood hat is conceived of as a possible easter egg for developers, I
  // think, so we'll hide it for now...
  // {
  //   id: 'Top_DogFood.png',
  //   filename: 'Top_DogFood.png',
  //   type: 'Top',
  //   availability: 'purchasable',
  //   displayName: 'Dog Food',
  //   price: PurchaseablePriceTier.A,
  // },
  {
    id: 'Top_Fedora.png',
    filename: 'Top_Fedora.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Fedora',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Top_HardHat.png',
    filename: 'Top_HardHat.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Hard Hat',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Top_HotCocoa.png',
    filename: 'Top_HotCocoa.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Hot Cocoa',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Top_IceCream.png',
    filename: 'Top_IceCream.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Ice Cream',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Top_Moon.png',
    filename: 'Top_Moon.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Moon',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Top_Mushroom.png',
    filename: 'Top_Mushroom.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Mushroom',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_Nest.png',
    filename: 'Top_Nest.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Bird Nest',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_PaintCan.png',
    filename: 'Top_PaintCan.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Paint Can',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_PirateHat.png',
    filename: 'Top_PirateHat.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Pirate',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_Pond.png',
    filename: 'Top_Pond.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Pond',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_Ramen.png',
    filename: 'Top_Ramen.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Ramen',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_StrawTop.png',
    filename: 'Top_StrawTop.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Straw Top',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Top_Submarine.png',
    filename: 'Top_Submarine.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Submarine',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Top_TopHat.png',
    filename: 'Top_TopHat.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Top Hat',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Top_UFO.png',
    filename: 'Top_UFO.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'UFO',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Mid_Axolotl.png',
    filename: 'Mid_Axolotl.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Axolotl',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Mid_Boba.png',
    filename: 'Mid_Boba.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Boba',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Mid_Cat.png',
    filename: 'Mid_Cat.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Cat',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Mid_Cup.png',
    filename: 'Mid_Cup.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Cup',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Mid_Dog.png',
    filename: 'Mid_Dog.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Dog',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Mid_Frog.png',
    filename: 'Mid_Frog.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Frog',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Mid_Koala.png',
    filename: 'Mid_Koala.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Koala',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Mid_Ladybug.png',
    filename: 'Mid_Ladybug.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Ladybug',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Mid_Monkey.png',
    filename: 'Mid_Monkey.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Monkey',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Mid_Narwhal.png',
    filename: 'Mid_Narwhal.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Narwhal',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Mid_Owl.png',
    filename: 'Mid_Owl.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Owl',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Mid_Penguin.png',
    filename: 'Mid_Penguin.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Penguin',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Mid_Rhino.png',
    filename: 'Mid_Rhino.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Rhino',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Mid_Robot.png',
    filename: 'Mid_Robot.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Robot',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Mid_Sloth.png',
    filename: 'Mid_Sloth.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Sloth',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Mid_Tree.png',
    filename: 'Mid_Tree.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Tree',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Mid_Zombie.png',
    filename: 'Mid_Zombie.png',
    type: 'Mid',
    availability: 'purchasable',
    displayName: 'Zombie',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Bottom_Backpacking.png',
    filename: 'Bottom_Backpacking.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Backpack',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Bottom_Barista.png',
    filename: 'Bottom_Barista.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Barista',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Bottom_Basket.png',
    filename: 'Bottom_Basket.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Basket',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Bottom_Bathrobe.png',
    filename: 'Bottom_Bathrobe.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Bathrobe',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Bottom_Dress.png',
    filename: 'Bottom_Dress.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Dress',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Bottom_Dress_02.png',
    filename: 'Bottom_Dress_02.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Dress 2',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Bottom_Dress_03.png',
    filename: 'Bottom_Dress_03.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Dress 3',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Bottom_Dress_04.png',
    filename: 'Bottom_Dress_04.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Dress 4',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Bottom_Dress_05.png',
    filename: 'Bottom_Dress_05.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Dress 5',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Bottom_DressShirt.png',
    filename: 'Bottom_DressShirt.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Dress Shirt',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Bottom_Floatie.png',
    filename: 'Bottom_Floatie.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Floatie',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Bottom_HazmatSuit.png',
    filename: 'Bottom_HazmatSuit.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Hazmat',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Bottom_Overalls.png',
    filename: 'Bottom_Overalls.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Overalls',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Bottom_ProfessorJacket.png',
    filename: 'Bottom_ProfessorJacket.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Professor',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Bottom_Robot.png',
    filename: 'Bottom_Robot.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Robot',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Bottom_SpaceSuit.png',
    filename: 'Bottom_SpaceSuit.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Space Suit',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Bottom_Tuxedo.png',
    filename: 'Bottom_Tuxedo.png',
    type: 'Bottom',
    availability: 'purchasable',
    displayName: 'Tuxedo',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Top_DiscordPopsicle.png',
    filename: 'Top_DiscordPopsicle.png',
    type: 'Top',
    availability: 'authenticated',
    displayName: 'Discord',
    price: 50,
  },
  {
    id: 'Top_Beret.png',
    filename: 'Top_Beret.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Béret',
    price: PurchaseablePriceTier.C,
  },
  {
    id: 'Top_ChefHat.png',
    filename: 'Top_ChefHat.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Chef Hat',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_ChurchHat.png',
    filename: 'Top_ChurchHat.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Church Hat',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Top_DevilHorns.png',
    filename: 'Top_DevilHorns.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Devil Horns',
    price: PurchaseablePriceTier.A,
  },
  {
    id: 'Top_SunHat.png',
    filename: 'Top_SunHat.png',
    type: 'Top',
    availability: 'purchasable',
    displayName: 'Sun Hat',
    price: PurchaseablePriceTier.B,
  },
  {
    id: 'Expression_Default.png',
    filename: 'Expression_Default.png',
    type: 'Expression',
    availability: 'default',
    displayName: 'Default',
    price: 0,
  },
  {
    id: 'Expression_Alarmed.png',
    filename: 'Expression_Alarmed.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Alarmed',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Annoyed.png',
    filename: 'Expression_Annoyed.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Annoyed',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Bashful.png',
    filename: 'Expression_Bashful.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Bashful',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Calm3.png',
    filename: 'Expression_Calm3.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Calm',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Crying.png',
    filename: 'Expression_Crying.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Crying',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Cute.png',
    filename: 'Expression_Cute.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Cute',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Derpy.png',
    filename: 'Expression_Derpy.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Derpy',
    price: PurchaseablePriceTier.D,
  },
  // {
  //   id: 'Expression_Derp.png',
  //   filename: 'Expression_Derp.png',
  //   type: 'Expression',
  //   availability: 'purchasable',
  //   displayName: 'Derp',
  //   price: PurchaseablePriceTier.D,
  // },
  // {
  //   id: 'Expression_Grimmace.png',
  //   filename: 'Expression_Grimmace.png',
  //   type: 'Expression',
  //   availability: 'purchasable',
  //   displayName: 'Grimmace',
  //   price: PurchaseablePriceTier.D,
  // },
  // {
  //   id: 'Expression_SoHappy.png',
  //   filename: 'Expression_SoHappy.png',
  //   type: 'Expression',
  //   availability: 'purchasable',
  //   displayName: 'So Happy',
  //   price: PurchaseablePriceTier.D,
  // },
  {
    id: 'Expression_Happy.png',
    filename: 'Expression_Happy.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Happy',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Mad.png',
    filename: 'Expression_Mad.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Mad',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Pouty.png',
    filename: 'Expression_Pouty.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Pouty',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Shocked.png',
    filename: 'Expression_Shocked.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Shocked',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Thinking.png',
    filename: 'Expression_Thinking.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Thinking',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Expression_Tired.png',
    filename: 'Expression_Tired.png',
    type: 'Expression',
    availability: 'purchasable',
    displayName: 'Tired',
    price: PurchaseablePriceTier.D,
  },
  {
    id: 'Top_Chelle_Lilypad.png',
    filename: 'Top_Chelle_Lilypad.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Chelle',
    price: 0,
  },
  {
    id: 'Top_KylieRouge_Sunflower.png',
    filename: 'Top_KylieRouge_Sunflower.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Kylie Rouge',
    price: 0,
  },
  {
    id: 'Top_Custom_Minecraft.png',
    filename: 'Top_Custom_Minecraft.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Minecraft',
    price: 0,
  },
  {
    id: 'Top_Custom_Midjourney.png',
    filename: 'Top_Custom_Midjourney.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Midjourney',
    price: 0,
  },
  {
    id: 'Top_Custom_Roblox.png',
    filename: 'Top_Custom_Roblox.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Roblox',
    price: 0,
  },
  {
    id: 'Top_Custom_EnglishLanguage.png',
    filename: 'Top_Custom_EnglishLanguage.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'English',
    price: 0,
  },
  {
    id: 'Top_Custom_MagicCircle.png',
    filename: 'Top_Custom_MagicCircle.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Magic Circle',
    price: 0,
  },
  {
    id: 'Top_Custom_MrBeast.png',
    filename: 'Top_Custom_MrBeast.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Straw Hat',
    price: 0,
  },
  {
    id: 'Top_Custom_Fortnite.png',
    filename: 'Top_Custom_Fortnite.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Supply Drop',
    price: 0,
  },
  {
    id: 'Top_DonutHalo.png',
    filename: 'Top_DonutHalo.png',
    type: 'Top',
    availability: 'claimable',
    displayName: 'Donut Halo',
    price: 0,
  },
  {
    id: 'Red',
    filename: 'Banner_Checkers.png',
    type: 'Color',
    availability: 'default',
    displayName: 'Red',
    price: 0,
  },
  {
    id: 'Orange',
    filename: 'Banner_Fire.png',
    type: 'Color',
    availability: 'default',
    displayName: 'Orange',
    price: 0,
  },
  {
    id: 'Yellow',
    filename: 'Banner_StarsYellow.png',
    type: 'Color',
    availability: 'default',
    displayName: 'Yellow',
    price: 0,
  },
  {
    id: 'Green',
    filename: 'Banner_Leaves.png',
    type: 'Color',
    availability: 'default',
    displayName: 'Green',
    price: 0,
  },
  {
    id: 'Blue',
    filename: 'Banner_Stripes.png',
    type: 'Color',
    availability: 'default',
    displayName: 'Blue',
    price: 0,
  },
  {
    id: 'Purple',
    filename: 'Banner_StarsPurple.png',
    type: 'Color',
    availability: 'default',
    displayName: 'Purple',
    price: 0,
  },
  {
    id: 'White',
    filename: 'Banner_Tiles.png',
    type: 'Color',
    availability: 'default',
    displayName: 'Grey',
    price: 0,
  },
  {
    id: 'Black',
    filename: 'Banner_Zigzags.png',
    type: 'Color',
    availability: 'default',
    displayName: 'Black',
    price: 0,
  },
];
