import { Trans } from '@lingui/react/macro';
import { GameMetaData } from '../types';
import Thumbnail from './assets/honeydew-thumbnail.png';
import Slide1 from './assets/howto/Slide1.png';
import Slide2 from './assets/howto/Slide2.png';
import Slide3 from './assets/howto/Slide3.png';
import Slide4 from './assets/howto/Slide4.png';

export default {
  name: 'Photobomb',
  primaryAccentColor: 'Purple.Magic',
  secondaryAccentColor: 'Purple.Pastel',
  thumbnailImage: Thumbnail,
  taglines: [<Trans>Mind Games</Trans>, <Trans>Strategic</Trans>],
  description: (
    <Trans>
      Pick the picture you think most will guess, but avoid bombs planted by
      other players!
    </Trans>
  ),
  elevatorPitch: (
    <Trans>
      Will you guide your friends to victory, or will you be the one to blow
      them up?
    </Trans>
  ),
  quickBits: [
    {
      emoji: '⏱️',
      text: <Trans>5-10 mins</Trans>,
    },
    {
      emoji: '👥',
      text: <Trans>3+ players</Trans>,
    },
  ],
  minPlayers: 3,
  howToSlides: [
    {
      img: Slide1,
      imgAlt:
        'PHOTOBOMB is a game of fitting in! But not with a dangerous crowd...',
      isFullScreenImage: true,
    },
    {
      img: Slide2,
      imgAlt: 'Each round, one player submits a short HINT for any photo.',
      isFullScreenImage: true,
    },
    {
      img: Slide3,
      imgAlt:
        'Earn points by picking the picture with the majority of votes or bombing other players.',
      isFullScreenImage: true,
    },
    {
      img: Slide4,
      imgAlt: 'The player with the most points wins!',
      isFullScreenImage: true,
    },
  ],
} satisfies GameMetaData;
